import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import { Text, View , StyleSheet} from 'react-native';
import { Overlay, Icon, Button } from '@rneui/themed';
import LoadingComp from '../../components/LoadingComp';
import { disableUser } from '../../server/Auth';
import { deleteReport } from '../../server/Reports';
import { ReportStruct } from '../../utils/Structs';

type Response = "del" | "usrlock"

const prompt : {[key : string] : {title: string, msg : string, yes: string, no : string}} = {
    del: {title: "Sind sie sich sicher?", msg: "Wollen sie diesen Nutzer löschen ?", yes: "Ja ich bin mir sicher!", no: "Nein lieber nicht!"},
    usrlock: {title: "Sind sie sich sicher?", msg: "Wollen sie diesen Nutzer sperren ?", yes: "Ja ich bin mir sicher!", no: "Nein lieber nicht!"},
}

interface Prop {
    visible: boolean;
    reset : () => void;
    item : ReportStruct;
}

export default function ReportConfirmation({visible, reset, item} : Prop) {
    
    const [ask, setAsk] = useState<Response>();
    const [loading, setLoading] = useState(false);

    const press = async (success : boolean) => {
        if(!success){
            setAsk(undefined)
        } else {
            setLoading(true)
            if(ask == "del"){
                await deleteReport(item.id);
            } else if(ask == "usrlock") {
                await disableUser(item.ruid);
            }
            setLoading(false);
        }
    }

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            margin: 20
        },
        overlay: {
            borderRadius: 25
        },
        icon: {
            
        },
        iconsubtitle: {
            textAlign: 'center'
        },
        column: {
            flex: 1,
            alignContent: 'center'
        },
        row: {
            flex: 1,
            flexDirection: 'row',
            marginTop: 20,
        },
        title: {
            textAlign: 'center',
            fontSize: 15,
        }
    })

    const getContent = () => {
        if(loading) {
            return(
                <LoadingComp />
            )
        }
        if(ask === undefined){
            return(
                <View style={styles.container}>
                    <Text style={styles.title}>Wie willst du diesen Fall abschließen ?</Text>
                    <View style={styles.row}>
                        <View style={styles.column}>
                            <Icon style={styles.icon} name="delete" type="material-community" onPress={() => setAsk('del')} size={30} />
                            <Text style={styles.iconsubtitle}>Meldung löschen</Text>
                        </View>
                        <View style={styles.column}>
                            <Icon style={styles.icon} name="user-lock" type="font-awesome-5" onPress={() => setAsk('usrlock')} size={30} />
                            <Text style={styles.iconsubtitle}>Nutzer sperren</Text>
                        </View>
                    </View>
                </View>
            )
        }else {
            const promp = prompt[ask];
            return(
                <View style={{justifyContent: 'center', alignItems: 'center', margin: 30}}>
                    <Text style={styles.title}>{promp.msg}</Text>
                    <View style={{flexDirection: 'row', width: '100%', marginTop: 20}}>
                        <Button icon={{
                            name: "check-circle",
                            type: "feather",
                            size: 30,
                            color: "white"
                        }} 
                        containerStyle={{width: '50%', borderTopEndRadius : 0, borderBottomEndRadius : 0}}
                        ViewComponent={LinearGradient}
                        linearGradientProps={{
                            colors: ['green', 'white'],
                            start: { x: 0, y: 0.5 },
                            end: { x: 1, y: 0.5 },
                        }}
                        onPress={() => press(true)}
                        />
                        <Button icon={{
                            name: "minus-circle",
                            type: "feather",
                            size: 30,
                            color: "darkred"
                        }}
                        containerStyle={{width: '50%'}}
                        ViewComponent={LinearGradient}
                        linearGradientProps={{
                            colors: ['red', 'white'],
                            start: { x: 1, y: 0.5 },
                            end: { x: 0, y: 0.5 },
                        }}
                        onPress={() => press(false)}
                        />
                    </View>
                </View>
            )
        }
    }

    return(
        <Overlay isVisible={visible} overlayStyle={styles.overlay} onBackdropPress={() => {if(!loading){ reset(); setAsk(undefined); }}}>
            {
                getContent()
            }
        </Overlay>
    )

}
