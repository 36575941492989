import React from 'react';
import {StyleProp, StyleSheet, Text, useWindowDimensions, View, ViewStyle} from 'react-native';
import { Button } from '@rneui/themed';
import { UserStruct } from '../utils/Structs';
import moment from 'moment-timezone';

const BoxRow = ({title, text} : {title : string, text : string}) => {

    return(
        <View style={{flex: 1, alignSelf: 'stretch', flexDirection: 'row', marginVertical: 5}}>
            <Text style={{flex: 1, alignSelf: 'stretch'}}>{title}</Text>
            <Text style={{flex: 1, alignSelf: 'stretch'}}>{text}</Text>
        </View>
    )
}

const typicalclaims = ["admin"];

interface Props {
    item : UserStruct;
    showClaims? : boolean;
    style? : StyleProp<ViewStyle>
}

export default function UserInfoBox({item, style, showClaims} : Props) {
    const {width} = useWindowDimensions()

    const user = item;

    const styles = StyleSheet.create({
        accountbox: {
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
            backgroundColor: 'white',
            borderRadius: 25,
            minWidth: width < 500 ? 300 : 500
        },
    });

    const getClaims = () => ( !showClaims ? null :
        typicalclaims.map((v) => (<BoxRow key={v} title={`Claim '${v}'`} text={(user.customClaims ? user.customClaims[v] ? user.customClaims[v].toString() : "Undefined" : "Undefined")} />))
    )

    return(
            <View style={[styles.accountbox, style]}>
                <BoxRow title={"Displayname :"} text={user.displayName ?? user.email ?? user.phoneNumber ?? user.uid} />
                <BoxRow title={"Created :"} text={moment(item.metadata.creationTime).locale("de").tz("Europe/Berlin").format("lll") ?? "Unbekannt"} />
                <BoxRow title={"Last Login :"} text={moment(item.metadata.lastSignInTime).locale("de").tz("Europe/Berlin").format("lll") ?? "Unbekannt"} />
                <BoxRow title={"Status :"} text={user.disabled ? "Deaktiviert" : "Aktiv"} />
                {
                    getClaims()
                }
            </View>
    )
}
