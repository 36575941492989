import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    initialRouteName: 'Root',
    screens: {
      Root: {
        initialRouteName: 'Root',
        screens: {
          Users : {
            initialRouteName: 'Root',
            path: 'users',
            screens: {
              User: 'user/:uid',
              Root: '/',
              UserHashMap: "user/:uid/hashmap",
              UserMarkerMap: "user/:uid/markermap"
            },
          },
          Reports: {
            initialRouteName: 'Root',
            path: 'reports',
            screens: {
              Root: '/',
              User: 'users/:id',
              ReportChatRoom: 'chat/:id'
            }
          }
        },
      },
      Account: {
        initialRouteName: 'Root',
        path: 'account',
        screens: {
          ServiceUserList: 'servicelist/:page',
          ServiceUser: 'suser'
        }
      },
      NotFound: '*',
      ChatRoom: 'chatroom/:id'
    },
  },
} as LinkingOptions;
