import { NavigationContainer } from '@react-navigation/native';
import React from 'react';
import DrawerNavigation from './DrawerNavigation';
import {createStackNavigator} from '@react-navigation/stack'
import NotFoundScreen from '../screens/NotFoundScreen';
import LinkingConfig from './LinkingConfig';
import AccountNavigator from './AccountNavigator';
import ChatRoom from '../screens/chats/ChatRoom';

export default function NavigationRoot() {
    return(
        <NavigationContainer
        linking={LinkingConfig}
        >
            <RootNavigator />
        </NavigationContainer>
    )
}

export type RootStackParamList = {
    Root : undefined;
    NotFound : undefined;
    Account : undefined;
    ChatRoom : {id : string, sender? : string}
}

const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Root" component={DrawerNavigation} options={{title: "Admin Interface"}} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Screen name="Account" component={AccountNavigator} options={{title : "Accountdetails"}} />
      <Stack.Screen name="ChatRoom" component={ChatRoom} options={{title: "Chatraum", headerShown: true, headerStyle: {backgroundColor: 'black'}, headerTintColor: 'white'}} />
    </Stack.Navigator>
  );
}