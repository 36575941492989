import { NavigationProp, useNavigation } from '@react-navigation/core';
import moment from 'moment-timezone';
import React from 'react';
import {View, Text, StyleSheet, Pressable} from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler';
import { ReportParamsList } from '../navigation/ReportNavigator';
import { parseType } from '../screens/reports';
import { ReportStruct } from '../utils/Structs';

interface Props {
    item : ReportStruct
}

type NProp = NavigationProp<ReportParamsList, 'Root'>

export default function ReportListItem({item} : Props) {

    const navigation = useNavigation<NProp>();

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
            alignSelf: 'stretch',
            padding: 10,
            backgroundColor: 'white',
            borderRadius: 25,
            marginVertical: 5
        },
        text: {
            flex: 1,
            alignSelf: 'stretch'
        }
    })

    return(
        <TouchableOpacity style={styles.container} onPress={() => navigation.navigate('User', {id : item.id})}>
            <Text style={styles.text}>{item.ruid}</Text>
            <Text style={styles.text}>{parseType(item.type)}</Text>
            <Text style={styles.text}>{moment.unix(item.sendtime._seconds).locale("de").tz("Europe/Berlin").format('lll')}</Text>
        </TouchableOpacity>
    )
}