import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import ChatInput from "./ChatInput";
import ChatList from "./ChatList";
import { ChatElement } from "./Structs";

interface Props {
    handleInput? : (msg : string) => void
    items : ChatElement[]
    containerStyle? : StyleProp<ViewStyle>
    onLoadMore: () => void
}

export default function Chat({items, containerStyle, onLoadMore, handleInput} : Props) {

    return(
        <View style={[{backgroundColor: 'black'}, containerStyle]}>
            <ChatList items={items} onLoadMore={onLoadMore} />
            {
                handleInput ? <ChatInput onSend={handleInput} /> : null
            }
        </View>
    )
}