
interface UserMetadata {
    lastSignInTime: string;
    creationTime: string;

}

export interface UserStruct {
    uid : string;
    photoURL? : string;
    disabled: boolean;
    phoneNumber?: string;
    email?: string;
    metadata: UserMetadata;
    displayName?: string;
    customClaims: {[key : string] : any};
}

export type RType = "img" | "msg" | "req";

interface Time {
    _nanoseconds : number;
    _seconds : number;
}

export interface ReportStruct {
    ruid : string;
    reportmsg : string;
    sendtime : Time;
    sender: string;
    type : RType;
    reporttype : string;
    roomid? : string;
    msgid? : string;
    message? : string;
    id : string;
}

export interface ExtendedReportStruct extends ReportStruct {
    id : string
}

export interface ReportChatStruct {
    message : string
    sentByUser? : boolean
    sendtime : number
}

export const TypicalUserClaims = ["admin"];

export interface ServerError {
    error: boolean;
    errormsg : string;
}

export interface MessageMedia {
    id : string
    width : number
    height : number
}

export interface MessageStruct {
    sender : string;
    sendtime : number;
    message : string;
    receivedAt : number;
    readAt : number;
    id : string; //ID Für Datenbankeinträge ?
    media? : MessageMedia;
}

export interface RequestStruct {
    sender : string;
    sendtime : number;
    endtime : number;
    message : string;
    number : string;
    r1 : boolean;
    r2 : boolean;
    r3 : boolean;
    id : string; //ID Für Datenbankeintrag
}

export interface RoomStruct {
    createdBy : string;
    users : string [];
    oldusers? : string [];
    creation : number;
    recentMessage : MessageStruct;
    modifiedAt : number;
    exchanged : boolean;
    numbers? : any; //Definition für Map ???
    id : string; //ID des Dokuments in der Datenbank
}

export interface ExchangeRequest {
    initBy: string
    created: number
    sentByMe?: boolean
}

export type Flowers = {r1? : number, r2? : number, r3? : number}

export interface UserInfo {
    coins? : number;
    tokens? : string[];
}

export interface LocationHash {
    sender: string,
    geohash: string[],
    timestamp: number
}

export interface Marker {
    uid: string,
    geohash: string,
    timestamp: number,
    coordinates: {
        lat: number,
        long: number
    },
    radius: number
}
