import {View, Text, StyleSheet} from "react-native";
import {useUserLocationHash} from "../../server/User";
import {Icon, ListItem} from "@rneui/themed";
import dayjs from "dayjs";
import {LocationHash} from "../../utils/Structs";
import {useRef, useState} from "react";
import CreateHash, {HashRef} from "./CreateHash";
import DeleteBtn from "../general/DeleteBtn";
import {useNavigation} from "@react-navigation/native";

interface Props {
    uid: string
}

export default function HashList({uid}: Props) {

    const navigation = useNavigation()

    const {userHashes} = useUserLocationHash(uid)

    const ref = useRef<HashRef>()

    return(
        <View style={styles.container}>
            <View style={{flexDirection: "row", alignItems: 'center'}}>
                <Text style={styles.title}>AutoMarkers</Text>
                <Icon name={"plus"} type={"octicon"} reverse onPress={() => ref.current?.open()} size={14} />
                {
                    userHashes && userHashes.length > 0 ?
                        <Icon name={"map"} type={"feather"} reverse onPress={() => navigation.navigate("UserHashMap", {uid: uid})} size={14} />
                        : null
                }
            </View>
            <View>
                {
                    userHashes && userHashes.length > 0 ? (
                        userHashes?.map((hash, index) => (
                            <Accordion key={hash.timestamp + hash.geohash.join()} hash={hash} />
                        ))
                    ) : (
                        <Text>No Hashes available</Text>
                    )
                }
            </View>
            <CreateHash uid={uid} ref={ref} />
        </View>
    )

}

function Accordion({hash} : {hash: LocationHash}) {
    const [open, setOpen] = useState(false)

    return(
        <ListItem.Accordion
            onPress={() => setOpen(!open)}
            isExpanded={open}
            key={hash.timestamp + hash.geohash.join()}
            content={
                <View style={styles.headerStyle}>
                    <Text style={{marginEnd: 10}}>{dayjs(hash.timestamp).format("LLL")}</Text>
                    <DeleteBtn onDelete={async () => {

                    }} />
                </View>
            }
        >
            {
                hash.geohash.map((hash) => (
                    <ListItem key={hash}>
                        <ListItem.Title style={styles.hashText}>{hash}</ListItem.Title>
                    </ListItem>
                ))
            }
        </ListItem.Accordion>
    )
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 25,
        backgroundColor: "white",
        padding: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: 15,
        fontWeight: "500"
    },
    headerStyle: {
        flexDirection: "row",
        alignItems: 'center'
    },
    hashText: {
        alignSelf: 'center'
    }
})
