import {useRef, useState, useEffect, useCallback} from 'react';
import {LocationHash, Marker, UserInfo, UserStruct} from '../utils/Structs';
import {getUser, getUserHashes, getUserInfo, getUserList} from './Auth';
import {getAuthToken, url as MainUrl} from "./index";
import {DBLocationHash, DBMarker} from "../utils/database/Structs";
import {getLocationHash, getMarker} from "../utils/database/DataTransformer";

interface UserState{
    end : boolean,
    users : UserStruct[],
    page : number;
}

export function useUserList(limit : number = 1000) {
    const decrease = () => {
        if(page.current === 1){
            return;
        }
        page.current--;
        updatePage();
    }

    const increase = () => {
        if(users.end === true){
            console.log("End reached");
            return;
        } else {
            console.log("Not")
            page.current++;
            updatePage();
        }
    }

    const [users, setUsers] = useState<UserState>({end : false, page: 1, users: []})
    const page = useRef(1);
    const list = useRef<UserStruct[]>([]);
    const ptokens = useRef<string>();

    const updatePage = async () => {
        if(list.current.length < limit * users.page) {
            const {pageToken, users} = await getUserList(limit, ptokens.current);
            list.current.push(...users);
            const end = !pageToken;
            setUsers({page: page.current, users : list.current.slice((page.current - 1) * limit, (page.current * limit -1)),end : end})
            console.log(end);
        } else {
            setUsers({page: page.current, users : list.current.slice((page.current - 1) * limit, (page.current * limit -1)), end: users.end})
        }
    }

    useEffect(() => {
        console.log("First fetch")
        updatePage();
    }, []);

    return {...users, previous : decrease, next: increase};
}

export function useUser(uid : string) {
    const [user, setUser] = useState<UserStruct>();

    useEffect(() => {
        getUser(uid).then((v) => {
            setUser(v)
        }).catch((e) => console.error(e))
    },[uid])
    return user;
}

export function useUserInfo(uid : string) {
    const [userinfo, setUserinfo] = useState<UserInfo>();

    useEffect(() => {
        update()
    }, [uid])

    const update = useCallback(() => getUserInfo(uid).then(setUserinfo).catch(console.error), [])

    return {userinfo, update};
}

export function useUserLocationHash(uid: string) {
    const [userHashes, setUserHashes] = useState<LocationHash[]>()

    const update = useCallback(() => getUserHashes(uid).then(setUserHashes).catch(console.error), [])

    useEffect(() => {
        update().catch(console.warn)
    }, [uid])

    return {userHashes, update}
}

export function useUserMarkers(uid: string) {
    const [userMarker, setUserMarker] = useState<Marker[]>()

    const update = useCallback(() => getUserMarkers(uid).then(setUserMarker).catch(console.error), [setUserMarker])

    useEffect(() => {
        update().catch(console.warn)
    }, [uid])

    return {userMarker, update}
}

export async function createUserLocationHash(uid: string, hashes: string[]) {
    const url = `${MainUrl}projc/user/${uid}/locationHashes`;

    const token = await getAuthToken();

    try{
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                hashes
            })
        });

        if(!response.ok) {
            const json = await response.json()
            if(!json){
                throw new Error("Unknown Error! Status : " + response.status);
            }
            if(json.error){
                throw new Error(json.errormsg)
            }
        }
    } catch(e){
        console.warn(e)
    }
}

export async function getUserMarkers(uid: string) {
    const url = `${MainUrl}projc/user/${uid}/markers`;

    const token = await getAuthToken();

    try{
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const json = await response.json();
        if(!json){
            console.warn("No response body!")
        }
        if(json.error){
            console.warn(json.error)
        }
        console.log(json)
        return (json as DBMarker[]).map(getMarker);
    } catch(e){
        console.warn(e)
    }
    return []
}
