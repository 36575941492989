import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import moment from 'moment-timezone';
import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button } from '@rneui/themed';
import { ReportParamsList } from '../../navigation/ReportNavigator';
import { parseType } from '../../screens/reports';
import { ReportStruct } from '../../utils/Structs';

type NProp = StackNavigationProp<ReportParamsList, 'User'>

interface Props {
    item: ReportStruct
}

export default function ReportInfoBox({item} : Props) {

    const navigation = useNavigation<NProp>()

    const styles = StyleSheet.create({
        container: {
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
            backgroundColor: 'white',
            borderRadius: 25,
        },
        rmsgbox: {
            alignItems: 'center',
            width: '100%'
        }
    })

    return(
        <View style={styles.container}>
            <BoxRow title={"Sender :"} text={item.sender} />
            <BoxRow title={"Meldungstyp :"} text={"Typ: "+parseType(item.type)} />
            <BoxRow title={"Gesendet am :"} text={moment.unix(item.sendtime._seconds).locale("de").tz("Europe/Berlin").format('lll')} />
            <BoxRow title={"Art der Meldung :"} text={item.reporttype} />
            <View style={styles.rmsgbox}>
                <Text style={{}}>Meldung des Nutzers</Text>
                <Text style={{marginTop: 20, backgroundColor: 'lightgrey', borderRadius: 25, padding: 20, width: '90%'}}>{item.reportmsg}</Text>
            </View>
            <Button style={{marginTop: 10}} title={"Report Chat"} onPress={() => navigation.navigate("ReportChatRoom", {id: item.id})} />
        </View>
    )
}

const BoxRow = ({title, text} : {title : string, text : string}) => {

    return(
        <View style={{flex: 1, alignSelf: 'stretch', flexDirection: 'row', marginVertical: 5}}>
            <Text style={{flex: 1, alignSelf: 'stretch'}}>{title}</Text>
            <Text style={{flex: 1, alignSelf: 'stretch'}}>{text}</Text>
        </View>
    )
}
