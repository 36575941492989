import React, { useRef, useState } from 'react'
import { StyleSheet, Text, View, Image } from 'react-native';
import { Button, Overlay } from '@rneui/themed'
import { TextInput } from 'react-native-gesture-handler';
import useChangeableCoins from '../hooks/useChangeableCoins';

interface Props {
    visible : boolean;
    coins? : number;
    reset : () => void;
    uid : string;
}

export default function UpdateCoins({visible, coins, reset, uid} : Props) {

    const {processing, change, setCoins} = useChangeableCoins(reset, uid, coins)

    const FlowerInput = ({coins} : {coins? : number}) => {
        const styles = StyleSheet.create({
            container: {
                flex: 1,
                flexDirection: "row",
                justifyContent: 'space-around',
                alignItems: 'center',
                width: "100%",
                backgroundColor: 'white',
                borderRadius: 25,
                marginVertical: 20,
                padding: 20
            },
            flower: {
                width: 25,
                height: 25
            },
            flowercontainer: {
                alignItems: 'center'
            },
            flowertext: {
                color: "black",
                textAlign: 'center'
            }
        })
    
        return(
            <View style={styles.container}>
                <View style={styles.flowercontainer}>
                    <Image style={styles.flower} source={require("../../assets/coins/Coin1.png")} />
                    <TextInput style={styles.flowertext} placeholder={(coins ?? 0).toString()} onChangeText={(text) => {if(text.length > 0) {setCoins(parseInt(text))}} } />
                </View>
                <Button title="Ändern" onPress={() => change()} />
            </View>
        )
    }

    return (
        <Overlay isVisible={visible} overlayStyle={{borderRadius: 25}} onBackdropPress={() => reset()}>
            {
                processing ? null : <FlowerInput coins={coins} />
            }
        </Overlay>
    )

}
