import { getAuthToken, url as MainUrl } from '.';
import { ServerError, UserStruct } from '../utils/Structs';
import {useRef, useState, useEffect} from 'react';


export async function getServiceUserList(pageLimit? : number, pagetoken? : string){
    const url = `${MainUrl}service/users`;

    const token = await getAuthToken();
    //console.log("token ", token)

        const response = await fetch(url, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                pagenumber: pageLimit,
                pagetoken: pagetoken,
                token: token
            })
        });
        if(!response.ok){
            throw new Error("Error happend "+response.text)
        }
        const json = await response.json();
        if(!json){
            throw new Error("Responsebody undefined!");
        }
        if(json.error){
            throw new Error(json.errormsg);
        }
        return json as {pageToken? : string, users: UserStruct[]};
}

export function useUserList(page : number, entrypp : number = 1000) {
    const ptoken = useRef<string>();
    const usersstate = useRef<{maxpage: number; users: UserStruct[]}>({maxpage: 0, users: []});
    const [actusers, setUsers] = useState<{users : UserStruct[], end : boolean}>({users: [], end: false});
    const {users, maxpage} = usersstate.current;
    
    useEffect(() => {
        if(page > maxpage){
            console.log("Fetching...");
            getServiceUserList(entrypp, ptoken.current).then((v) => {
                ptoken.current = v.pageToken;
                users.push(...v.users);
                usersstate.current = {maxpage: page, users: users};
                const end = v.users.length !== entrypp;
                setUsers({users: v.users, end: end});
            }).catch((e) => console.error(e));
        } else {
            const pageusers = users.slice((page-1) * entrypp, (page * entrypp) - 1);
            setUsers({users: pageusers, end: actusers.end});
        }
    }, [page]);

    return actusers;
}

export async function getServiceUser(uid : string) {
    const url = `${MainUrl}service/user`;

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid : uid
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    console.log(json.users);
    if(json.error){
        throw new Error(json.errormsg)
    }
    return json as UserStruct;
}

export function useServiceUser(uid : string) {
    const [user, setUser] = useState<UserStruct | null>();

    useEffect(() => {
        getServiceUser(uid).then((v) => {
            setUser(v);
        }).catch((e) => {
            console.error(e);
            setUser(null);
        })
    },[])

    return user;
}

export async function setServiceUser(uid : string, claims : {[key : string] : any}) {
    const url = `${MainUrl}service/userclaims`;

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid : uid,
            claims: claims
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    if(json.error){
        throw new Error(json.errormsg)
    }
    return json.success;
}