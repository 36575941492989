import {Input, Overlay} from "@rneui/themed";
import {forwardRef, Ref, useImperativeHandle, useState} from "react";
import {Button, Text, TextInput, View} from "react-native";
import {createUserLocationHash} from "../../server/User";
import CreateOverlay from "./CreateOverlay";

export type HashRef = {
    open: () => void
}

interface Props {
    uid: string
}

export default forwardRef<HashRef, Props>(CreateHash)

function CreateHash({uid} : Props, ref : Ref<HashRef>) {
    const [hash, setHash] = useState("")

    const [creating, setCreating] = useState(false)

    const createHash = () => {
        setCreating(true)
        createUserLocationHash(uid, [hash])
            .catch(console.warn)
        setCreating(false)
    }

    return(
        <CreateOverlay ref={ref} refFkt={{}}>
            <View>
                <Input onChangeText={setHash} value={hash} placeholder={"Hash"} autoCompleteType={undefined} label={"Hashes"} />
                <Button title={"Create"} onPress={() => {
                    createHash()
                }} disabled={creating} />
            </View>
        </CreateOverlay>
    )
}
