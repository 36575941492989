import {Overlay} from "@rneui/themed";
import React, {forwardRef, Ref, useImperativeHandle, useState} from "react";
import {OverlayProps} from "@rneui/base/dist/overlay/Overlay";

export type CreateOverlayRef = {
    open: () => void
}

interface Props<T> extends Partial<OverlayProps>{
    // Currently no custom props
    refFkt: T
}

function CreateOverlayComponent<T>({refFkt, ...overlayProps} : Props<T>, ref : Ref<CreateOverlayRef & T>) {
    const [open, setOpen] = useState(false)

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(!open)
        },
        ...refFkt
    }), [open, setOpen])

    return(
        <Overlay isVisible={open} onBackdropPress={() => setOpen(false)} {...overlayProps} />
    )
}

const CreateOverlay = forwardRef(CreateOverlayComponent) as <T>(
    props: Props<T> & { ref?: React.ForwardedRef<CreateOverlayRef & T> }
) => ReturnType<typeof CreateOverlayComponent>;

export default CreateOverlay
