import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import UserList from '../components/UserList';
import { UserListSortingProvider } from '../contexts/UserListSortingContext';

export default function UsersScreen() {
    
    const styles = StyleSheet.create({
        container: {
            flex: 1,
            alignContent: 'center',
            margin: 5
        }
    });

    return(
        <View style={styles.container}>
            <UserListSortingProvider>
                <UserList/>
            </UserListSortingProvider>
        </View>
    )
}