import { getAuthToken, url as MainUrl } from '.';
import {Flowers, LocationHash, UserInfo, UserStruct} from '../utils/Structs';
import {getLocationHash} from "../utils/database/DataTransformer";
import {DBLocationHash} from "../utils/database/Structs";


export async function getUserList(pageLimit? : number, pagetoken? : string) {
    const url = `${MainUrl}projc/users`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            pagenumber: pageLimit,
            pagetoken: pagetoken,
            token: token,
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    console.log(json.users);
    return json as {pageToken? : string, users: UserStruct[]};

}

export async function getUser(uid : string) {
    const url = `${MainUrl}projc/user`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid : uid,
            token: token
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    if(json.error){
        throw new Error(json.errormsg)
    }
    return json as UserStruct;
}

export async function getUserImg(uid : string) {
    const url = `${MainUrl}projc/userimg`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid : uid,
            token: token
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    console.log(json.users);
    if(json.error){
        throw new Error(json.errormsg)
    }
    return json.imgurl as string;
}

export async function disableUser(uid : string) {
    const url = `${MainUrl}projc/disableuser`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid : uid,
            token: token
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    if(json.error){
        throw new Error(json.errormsg)
    }
}

export async function enableUser(uid : string) {
    const url = `${MainUrl}projc/enableuser`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid : uid,
            token: token
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    if(json.error){
        throw new Error(json.errormsg)
    }
}

export async function deleteUser(uid : string) {
    const url = `${MainUrl}projc/deleteuser`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid : uid,
            token: token
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    if(json.error){
        throw new Error(json.errormsg)
    }
}

export async function deleteUserImg(uid : string) {
    const url = `${MainUrl}projc/deleteimg`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid : uid,
            token: token
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    if(json.error){
        throw new Error(json.errormsg)
    }
}

export async function updateFlowers(uid : string, flowers : Partial<Flowers>) {
    const url = `${MainUrl}projc/updateflowers`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid : uid,
            flowers : flowers,
            token: token
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    if(json.error){
        throw new Error(json.errormsg)
    }
}

export async function updateCoins(uid : string, coins : number) {
    const url = `${MainUrl}projc/updateCoins`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uid : uid,
            coins : coins,
            token: token
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    if(json.error){
        throw new Error(json.errormsg)
    }
}

export async function getUserInfo(uid : string) {
    const url = `${MainUrl}projc/getUserInfo`;

    const token = await getAuthToken();

    //console.log(`token : ${token}`)

    try{
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uid : uid,
                token: token
            })
        });

        const json = await response.json();
        if(!json){
            throw new Error("Responsebody undefined!");
        }
        if(json.error){
            throw new Error(json.errormsg)
        }
        console.log(json)
        return json as UserInfo;
    } catch(e){
        console.warn(e)
    }
    return {} as UserInfo
}

export async function getUserHashes(uid : string) {
    const url = `${MainUrl}projc/user/${uid}/locationHashes`;

    const token = await getAuthToken();

    try{
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const json = await response.json();
        if(!json){
            throw new Error("Responsebody undefined!");
        }
        if(json.error){
            throw new Error(json.errormsg)
        }
        console.log(json)
        return (json as DBLocationHash[]).map(getLocationHash);
    } catch(e){
        console.warn(e)
    }
    return []
}
