import React, {useCallback, useState} from 'react'
import { Keyboard, StyleSheet, TextInput } from 'react-native'
import { View } from 'react-native'
import { Icon } from '@rneui/themed'
import { useTheme } from '../../../utils/Style'

interface ImageEvent {
    uri : string
    linkUri : string
    mime : string
    data : string
}

interface Props {
    onSend : (msg : string) => void
}

export default function ChatInput({onSend} : Props) {
    const [message, setMessage] = useState<string>("")
    const Style = useTheme()

    return(
        <View style={styles.sendcontainer}>
            <TextInput value={message} style={[styles.sendinput, {color: Style.textcolor, backgroundColor: Style.type == "dark" ? "#040912" : Style.componentcolor}]} placeholderTextColor={Style.textcolor} placeholder={"Schreibe eine Nachricht!"} multiline onChangeText={(text) => setMessage(text)} maxLength={250000} />
            { message.length > 0 ? 
            <Icon size={44} style={styles.sendicon} name={"send"} onPress={() => {setMessage(""); onSend(message)}} /> 
            : null
            }
        </View>
    )
}

const styles = StyleSheet.create({
    sendcontainer : {
        maxHeight: 100,
        minHeight: 45,
        flex : 0,
        flexDirection : 'row',
        alignItems: 'flex-end',
        marginBottom: 5,
        marginHorizontal: 5,
    },
    sendinput : {
        padding : 10,
        flex : 1,
        backgroundColor: 'white',
        borderRadius: 25,
        elevation: 1,
        shadowColor: "#00000090",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.18,
        shadowRadius: 1.00,
    },
    sendicon :{
        flex: 0,
        marginStart: 5
    },
})
