import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { AccountStackParamList } from '../../navigation/AccountNavigator';
import { UserStruct } from '../../utils/Structs';

type NProp = StackNavigationProp<AccountStackParamList, 'ServiceUserList'>

interface Props {
    item : UserStruct;
}

export default function ServiceUserListItem({item} : Props) {

    const navigation = useNavigation<NProp>();

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
            alignSelf: 'stretch',
            padding: 10,
            backgroundColor: 'white',
            borderRadius: 25,
            marginVertical: 5
        },
        text: {
            flex: 1,
            alignSelf: 'stretch'
        }
    })

    return(
        <Pressable style={styles.container} onPress={() => navigation.navigate("ServiceUser", {uid : item.uid})}>
            <Text style={styles.text}>{item.email ?? item.uid}</Text>
            <Text style={styles.text}>{item.metadata.lastSignInTime}</Text>
            <Text style={styles.text}>{item.metadata.creationTime}</Text>
        </Pressable>
    )
}