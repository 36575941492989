import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import AccountScreen from '../screens/account';
import ServiceUser from '../screens/account/ServiceUser';
import ServiceUserList from '../screens/account/ServiceUserList';

export type AccountStackParamList = {
    Root : undefined;
    ServiceUserList : {page? : number};
    ServiceUser : {uid : string};
}

const AccountStack = createStackNavigator<AccountStackParamList>();

export default function AccountNavigator() {


    return(
        <AccountStack.Navigator>
            <AccountStack.Screen name="Root" component={AccountScreen} options={{headerTitle: "Account"}} />
            <AccountStack.Screen name="ServiceUserList" component={ServiceUserList} options={{headerTitle: "Service Userlist"}} />
            <AccountStack.Screen name="ServiceUser" component={ServiceUser} options={{headerTitle: "Service User"}} />
        </AccountStack.Navigator>
    )
}