import {Timestamp} from "firebase/firestore";
import {ExchangeRequest, LocationHash, Marker, MessageStruct, ReportChatStruct, RoomStruct} from "../Structs";
import {
    DBExchangeRequest,
    DBLocationHash,
    DBMarker,
    DBMessageStruct,
    DBReportChatStruct,
    DBRoomStruct
} from "./Structs";
import {getTimestamp} from "react-native-reanimated/lib/types/lib";

export function getMessageStruct(dbmsg : DBMessageStruct) : MessageStruct {
    return {
        ...dbmsg,
        sendtime: getTimeMillis(dbmsg.sendtime) //Ignore ?
    }
}

export function getDBMessageStruct(msg : MessageStruct) : DBMessageStruct {
    return {
        ...msg,
        sendtime: Timestamp.fromMillis(msg.sendtime)
    }
}

export function getRoomStruct(DBRoomStruct : DBRoomStruct) : RoomStruct {
    return {
        ...DBRoomStruct,
        recentMessage: getMessageStruct(DBRoomStruct.recentMessage)
    }
}

export function getDBRoomStruct(room : RoomStruct) : DBRoomStruct {
    return {
        ...room,
        recentMessage: getDBMessageStruct(room.recentMessage)
    }
}

export function getExchangeRequest(DBExReq : DBExchangeRequest) : ExchangeRequest {
    return {
        ...DBExReq,
        created: DBExReq.created ? DBExReq.created._seconds * 1000 : Date.now()
    }
}

export function getDBExchangeRequest(exchangeRequest : ExchangeRequest) : DBExchangeRequest{
    return {
        ...exchangeRequest,
        created: Timestamp.fromMillis(exchangeRequest.created)
    }
}

export function getReportChat(DBReportChat : DBReportChatStruct) : ReportChatStruct {
    return {
        ...DBReportChat,
        sendtime: DBReportChat.sendtime ? DBReportChat.sendtime._seconds * 1000 : Date.now()
    }
}

export function getDBReportChat(reportchat : ReportChatStruct) : DBReportChatStruct{
    return {
        ...reportchat,
        sendtime: Timestamp.fromMillis(reportchat.sendtime)
    }
}

export function getLocationHash(dbLocationHash: DBLocationHash) : LocationHash{
    return {
        ...dbLocationHash,
        timestamp: dbLocationHash.timestamp ? dbLocationHash.timestamp._seconds * 1000 : Date.now()
    }
}

export function getMarker(dbMarker: DBMarker) : Marker {
    return {
        uid: dbMarker.uid,
        coordinates: {
            lat: dbMarker.coordinates._latitude,
            long: dbMarker.coordinates._longitude
        },
        timestamp: dbMarker.timestamp ? dbMarker.timestamp._seconds * 1000 : Date.now(),
        geohash: dbMarker.g.geohash,
        radius: dbMarker.radius
    }
}

export function getTimeMillis(time : number | Timestamp) {
    console.log(time)
    if(Number.isInteger(time)){
        return time as number;
    }
    if(time){
        const timestamp = time as Timestamp
        // @ts-ignore
        return timestamp._seconds*1000
    }
    //Return undefined else
}
