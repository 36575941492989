import { NavigationProp, RouteProp } from "@react-navigation/native";
import React, { useEffect, useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";
import Chat from "../../components/general/chats/Chat";
import { ChatElement } from "../../components/general/chats/Structs"
import { ReportParamsList } from "../../navigation/ReportNavigator";
import { useReportMessages } from "../../server/Reports";
import { useTheme } from "../../utils/Style";

type NProp = NavigationProp<ReportParamsList, 'ReportChatRoom'>
type RProp = RouteProp<ReportParamsList, 'ReportChatRoom'>

interface Props {
    navigation : NProp
    route : RProp
}

export default function ReportChatRoom({route, navigation} : Props) {

    const {id} = route.params
    const {active, messages, sendReportMessage} = useReportMessages(id)
    const parsed = useMemo(() => messages.map((v) => ({message: v.message, timestamp: v.sendtime, sender: !v.sentByUser, id: v.message + v.sendtime} as ChatElement)), [messages])
    const Style = useTheme()

    useEffect(() => {
        navigation.setOptions({
            headerTitle: `Report Chat : ${id}`
        })
    }, [id])

    return(
        <Chat items={parsed} containerStyle={StyleSheet.absoluteFill} onLoadMore={() => {}} handleInput={(msg) => sendReportMessage(msg)} />
    )
}