import React from 'react'
import { StyleSheet, View, Text, Image } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

interface Props{
    coins? : number;
    onPress : () => void
}

export default function CoinInfoBox({coins, onPress} : Props) {

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: "row",
            justifyContent: 'space-around',
            alignItems: 'center',
            width: "100%",
            backgroundColor: 'white',
            borderRadius: 25,
            marginVertical: 20,
            padding: 20
        },
        flower: {
            width: 25,
            height: 25
        },
        flowercontainer: {
            alignItems: 'center'
        },
        flowertext: {
            color: "black",
            textAlign: 'center'
        },
        touchctn: {
            width: "50%"
        }
    })

    return(
        <TouchableOpacity containerStyle={styles.touchctn} onPress={onPress}>
        <View style={styles.container}>
            <View style={styles.flowercontainer}>
                <Image style={styles.flower} source={require("../../assets/coins/Coin1.png")} />
                <Text style={styles.flowertext}>{coins ?? 0}</Text>
            </View>
        </View>
        </TouchableOpacity>
    )
}