import { useNavigation } from '@react-navigation/core';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import React from 'react';
import { Icon } from '@rneui/themed';
import { DrawerParamList } from '../navigation/DrawerNavigation';

type HeaderNavLeft = DrawerNavigationProp<DrawerParamList,'Root'>

export default function HeaderLeft() {
    const navigation = useNavigation<HeaderNavLeft>();

    return(
        <Icon name="menu" style={{width: 50}} onPress={() => {navigation.toggleDrawer()}} />
    )
}
