import { useCallback, useEffect, useRef, useState } from 'react';
import { getAuthToken, url as MainUrl, wsurl as MainWSUrl } from '.';
import { getMessageStruct, getReportChat } from '../utils/database/DataTransformer';
import { DBMessageStruct, DBReportChatStruct } from '../utils/database/Structs';
import { ExtendedReportStruct, MessageStruct, ReportChatStruct, ReportStruct } from '../utils/Structs';
import {Timestamp} from 'firebase/firestore';

export async function getReports(limit : number = 50, starttimestamp? : number) {
    const url = `${MainUrl}projc/reports`;

    const token = await getAuthToken();

        const response = await fetch(url, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                limit: limit,
                starttimestamp: starttimestamp,
                token: token
            })
        });
        const json = await response.json();
        if(!json){
            throw new Error("Responsebody undefined!");
        }
        if(json.error){
            throw new Error(json.errormsg);
        }
        return json as ReportStruct[];
}

interface ReportsState {
    page: number;
    reports : ReportStruct[];
    end : boolean;
}

export function useReports(limit : number = 25) {
    const decrease = () => {
        if(page.current === 1){
            return;
        }
        page.current--;
        updatePage();
    }

    const increase = () => {
        if(reports.end === true){
            console.log("End reached");
            return;
        } else {
            console.log("Not")
            page.current++;
            updatePage();
        }
    }

    const [reports, setReports] = useState<ReportsState>({end : false, page: 1, reports: []})
    const page = useRef(1);
    const list = useRef<ReportStruct[]>([]);

    const updatePage = async () => {
        if(list.current.length < limit * reports.page) {
            const lasttimestamp = list.current[list.current.length]?.sendtime;
            const nfetch = await getReports(limit, lasttimestamp?._nanoseconds);
            list.current.push(...nfetch);
            const end = nfetch.length < limit;
            setReports({page: page.current, reports : list.current.slice((page.current - 1) * limit, (page.current * limit -1)),end : end})
            console.log(end);
        } else {
            setReports({page: page.current, reports : list.current.slice((page.current - 1) * limit, (page.current * limit -1)),end: reports.end})
        }
    }

    useEffect(() => {
        console.log("First fetch")
        updatePage();
    }, []);

    return {...reports, previous : decrease, next: increase};
}

export async function getReport(id : string) {
    const url = `${MainUrl}projc/report`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id,
            token: token
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    if(json.error){
        throw new Error(json.errormsg);
    }
    return json as ReportStruct;
}

export function useReport(id : string) {
    const [report, setReport] = useState<ReportStruct>();

    useEffect(() => {
        getReport(id).then((v) => {
            setReport(v)
        }).catch((e) => console.error(e));
    },[id])

    return report;
}

export async function deleteReport(id : string) {
    const url = `${MainUrl}projc/delreport`;

    const token = await getAuthToken();

    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id,
            token: token
        })
    });
    const json = await response.json();
    if(!json){
        throw new Error("Responsebody undefined!");
    }
    if(json.error){
        throw new Error(json.errormsg);
    }
}

export function useReportMessages(id : string) {
    const [messages, setMessages] = useState<ReportChatStruct[]>([])
    const [active, setActive] = useState(false)

    const websocket = useRef<WebSocket>()

    useEffect(() => {
        const run = async () => {
            websocket.current = await getReportMessagesSocket(id)
            websocket.current.onopen = () => setActive(true)
            websocket.current.onclose = () => setActive(false)
            websocket.current.onmessage = (e) => {
                const data = JSON.parse(e.data) as DBReportChatStruct[] ?? []
                const mapData = data.map((v) => {
                    const data = getReportChat(v)
                    return data
                })
                setMessages(mapData)
            }
        }
        run().catch(console.warn)

        return () => websocket.current?.close()
    }, [])

    const sendReportMessage = useCallback((msg : string) => {
        websocket.current?.send(JSON.stringify({
            message: msg,
            sendtime: Timestamp.now()
        } as DBReportChatStruct))
    }, [])

    return {active, messages, sendReportMessage}
}

export async function getReportMessagesSocket(id : string){
    const token = await getAuthToken()
    const url = `${MainWSUrl}reports?id=${id}&token=${token}`;
    return new WebSocket(url);
}

export function useChatMessages(id : string) {
    const [messages, setMessages] = useState<MessageStruct[]>([])
    const [active, setActive] = useState(false)
    const [limit, setLimit] = useState(25)

    const websocket = useRef<WebSocket>()

    const loadMore = useCallback(() => {
        const newLimit = limit + 25;
        websocket.current?.send(JSON.stringify({
            "limit": newLimit
        }))
        setLimit(newLimit)
    }, [limit])

    useEffect(() => {
        const run = async () => {
            websocket.current = await getChatMessagesSocket(id)
            websocket.current.onopen = () => setActive(true)
            websocket.current.onclose = () => setActive(false)
            websocket.current.onmessage = (e) => {
                const data = JSON.parse(e.data) as DBMessageStruct[] ?? []
                const mapData = data.map((v) => getMessageStruct(v))
                setMessages(mapData)
            }
        }
        run().catch(console.warn)

        return () => websocket.current?.close()
    }, [])

    return {active, messages, loadMore}
}

export async function getChatMessagesSocket(id : string){
    const token = await getAuthToken()
    const url = `${MainWSUrl}chats?id=${id}&token=${token}`;
    return new WebSocket(url);
}
