import { RouteProp } from '@react-navigation/core';
import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Button } from '@rneui/themed';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingComp from '../../components/LoadingComp';
import UserInfoBox from '../../components/UserInfoBox';
import { AccountStackParamList } from '../../navigation/AccountNavigator';
import { setServiceUser, useServiceUser } from '../../server/Service';
import { TypicalUserClaims, UserStruct } from '../../utils/Structs';

type RProp = RouteProp<AccountStackParamList, 'ServiceUser'>;

interface Props {
    route: RProp;
}

const msgs : {[key : string] : {title: string, value : any}} = {
    admin: {title: "Set as admin!", value: true}
}

export default function ServiceUser({route} : Props) {

    const {uid} = route.params;
    const user = useServiceUser(uid);
    const availableClaims = useMemo(() => user ? TypicalUserClaims.filter((v) => user.customClaims ? user.customClaims[v] === undefined : true) : [], [user]);

    if(user === undefined) {
        return(
            <LoadingComp />
        )
    }

    if(user === null) {
        return (
            <ErrorComponent errormsg="User undefined!" />
        )
    }

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        claimctn: {
            backgroundColor: 'white',
            borderRadius: 25,
            padding: 20
        },
        userinfobox: {
            marginVertical: 20
        }
    })

    console.log(user);

    const mapAvailableClaims = () => (
        availableClaims.map((v) => (
            <Button key={v} title={msgs[v].title} onPress={() => setServiceUser(uid, {[v] : msgs[v].value})} />
        ))
    )

    return(
        <View style={styles.container}>
            <UserInfoBox item={user} style={styles.userinfobox} showClaims={true} />
            {
                availableClaims.length > 0 ? (
                    <View style={styles.claimctn} >
                        {
                            mapAvailableClaims()
                        }
                    </View>
                ) : null
            }
            
        </View>
    )
}
