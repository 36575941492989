import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import UsersScreen from '../screens/UsersScreen';
import HeaderLeft from '../components/HeaderLeft';
import UserInfoScreen from '../screens/users/UserInfoScreen';
import HeaderRight from '../components/HeaderRight';
import UserHashMapScreen from "../screens/users/UserHashMapScreen";
import UserMarkerMapScreen from "../screens/users/UserMarkerMapScreen";

export type UserStackParams = {
    Root : undefined;
    User : {uid : string};
    UserHashMap : {uid : string};
    UserMarkerMap : {uid : string};
}

const UserStack = createStackNavigator<UserStackParams>();

export default function UserStackNavigator(){

    return(
        <UserStack.Navigator
        initialRouteName="Root"
        >
            <UserStack.Screen name="Root" component={UsersScreen} options={{title: "Nutzerliste", headerLeft: HeaderLeft, headerRight: HeaderRight, headerRightContainerStyle: {marginEnd: 10}}} />
            <UserStack.Screen name="User" component={UserInfoScreen} options={{title: "Nutzer"}} />
            <UserStack.Screen name="UserHashMap" component={UserHashMapScreen} options={{title: "Hashmap"}} />
            <UserStack.Screen name="UserMarkerMap" component={UserMarkerMapScreen} options={{title: "Hashmap"}} />
        </UserStack.Navigator>
    )

}
