import React from 'react';
import { View } from 'react-native';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';

export default function HeaderComp() {

    return(
        <View style={{flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: 'lightgrey', width: '100%', height: 65, alignItems: 'center'}}>
            <HeaderLeft />
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end', marginEnd: 20}}>
                <HeaderRight />
            </View>
        </View>
    )
}