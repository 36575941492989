import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { Button } from '@rneui/themed';
import { SortType, UserListSortingContext, UserListSortingProvider } from '../contexts/UserListSortingContext';
import { useUserList } from '../server/User';
import { UserStruct } from '../utils/Structs';
import ListHeader from './ListHeader';
import LoadingComp from './LoadingComp';
import UserListItem from './UserListItem';

export default function UserList() {

    const {sort} = useContext(UserListSortingContext)
    
    const {users, next, previous, end} = useUserList();

    const sorted = useMemo(() => {
        const arr = sort ? sortBy(users, sort.type) : users
        if(sort?.sort == "desc"){
            arr.reverse()
        }
        return arr
    }, [sort, users])

    if(users.length === 0) {
        return(
            <LoadingComp />
        )
    }

    const renderItem = ({item} : {item : UserStruct}) => (
        <UserListItem item={item} />
    )

    const keyExtractor = (item : UserStruct, index : number) => (item.uid)

    console.log(users.length);

    const styles = StyleSheet.create({
        container: {
            flex: 1,

        },
        flatlist: {
            marginTop: 10,
            width: "96%",
            alignSelf: 'center'
        },
        lastrow: {
            flexDirection: 'row',
            justifyContent: 'flex-end'
        }
    })

    return(
        <View style={styles.container}>
            <FlatList style={styles.flatlist} data={sorted} renderItem={renderItem} keyExtractor={keyExtractor} ListHeaderComponent={ListHeader} />
            <View style={styles.lastrow}>
                <Button title="Weiter" disabled={end} onPress={() => next()} />
            </View>
        </View>
    )
}

function sortBy(users: UserStruct[],type: SortType): UserStruct[] {
    if(type == "Number"){
        return users.sort((a, b) => compare(a?.phoneNumber, b?.phoneNumber, (a, b) => a!.localeCompare(b!)))
    } else if(type == "Login"){
        return users.sort((a, b) => moment(a.metadata.lastSignInTime).diff(b.metadata.lastSignInTime))
    } else if(type == "Creation"){
        return users.sort((a, b) => moment(a.metadata.creationTime).diff(b.metadata.creationTime))
    }

    return users
}

function compare<X, Y>(a: X, b: Y, compare: (a: X, b: Y) => number): number {
    if(!a && !b){
        return 0
    }
    if(!a){
        return 1
    }
    if(!b){
        return -1
    }
    return compare(a,b)
}
