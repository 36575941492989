import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { UserStruct } from '../utils/Structs';
import moment from 'moment-timezone';
import { StackNavigationProp } from '@react-navigation/stack';
import { UserStackParams } from '../navigation/UserStackNavigator';
import { useNavigation } from '@react-navigation/core';
import { TouchableOpacity } from 'react-native-gesture-handler';

type NProp = StackNavigationProp<UserStackParams, 'Root'>;

interface Props {
    item : UserStruct;
}

export default function UserListItem({item} : Props) {

    const navigation = useNavigation<NProp>();

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
            alignSelf: 'stretch',
            padding: 10,
            backgroundColor: item.disabled ? 'lightgrey' : 'white',
            borderRadius: 25,
            marginVertical: 5,
        },
        text: {
            flex: 1,
            alignSelf: 'stretch'
        }
    })

    return(
        <TouchableOpacity style={styles.container} onPress={() => navigation.navigate('User', {uid : item.uid})}>
            <Text style={styles.text}>{item.phoneNumber ?? item.uid}</Text>
            <Text style={styles.text}>{moment(item.metadata.lastSignInTime).locale("de").tz("Europe/Berlin").format("lll")}</Text>
            <Text style={styles.text}>{moment(item.metadata.creationTime).locale("de").tz("Europe/Berlin").format("lll")}</Text>
        </TouchableOpacity>
    )
}