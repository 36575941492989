import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import HeaderLeft from '../components/HeaderLeft';
import HeaderRight from '../components/HeaderRight';
import ReportScreen from '../screens/reports';
import ReportChatRoom from '../screens/reports/ReportChatRoom';
import ReportedUser from '../screens/reports/ReportedUser';

export type ReportParamsList = {
    Root: undefined;
    User: {id : string};
    ReportChatRoom : {id : string}
}

const ReportStack = createStackNavigator<ReportParamsList>();

export default function ReportNavigator() {
    return(
        <ReportStack.Navigator
        initialRouteName="Root"
        >
            <ReportStack.Screen name="Root" component={ReportScreen} options={{headerTitle: "Reports", headerLeft: HeaderLeft, headerRight: HeaderRight, headerRightContainerStyle: {marginEnd: 10}}} />
            <ReportStack.Screen name="User" component={ReportedUser} />
            <ReportStack.Screen name="ReportChatRoom" component={ReportChatRoom} options={{headerTitle: "Report Chat", headerStyle: {backgroundColor: 'black'}, headerTintColor: 'white'}} />
        </ReportStack.Navigator>
    )
}