import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { FlatList } from "react-native";
import ChatMessageItem from "./ChatElement";
import { ChatElement } from "./Structs";
import * as Localization from 'expo-localization';
import ChatTimeView from "./ChatTimeView";

interface Props {
    items : ChatElement[]
    onLoadMore: () => void
    selection : string
    onSelect: (id : string) => void
}

interface ListItem{
    time? : number;
    message? : ChatElement
}

export default function ChatList({items, selection, onSelect, onLoadMore} : Props) {

    const parsed = useMemo(() => parseMessages(items),[items])

    const renderItem = useCallback(({item} : {item : ListItem}) => {
        if(item.message){
            return <ChatMessageItem msg={item.message} sender={item.message.sender} />
        }
        return <ChatTimeView time={item.time ?? 0} />
    }, [])

    return (
        <FlatList data={parsed} renderItem={renderItem} keyExtractor={keyExtractor} inverted onEndReachedThreshold={0.1} onEndReached={({distanceFromEnd}) => {console.log("end " + distanceFromEnd); onLoadMore()}} />
    )
}

function keyExtractor (item : ListItem, index : number) {
    if(item.message) {
        return item.message.id
    }
    if(item.time) {
        return item.time.toString()
    }
    return index.toString()
}

function parseMessages(msgs : ChatElement[]) {
    return msgs.flatMap((v, i, arr) => {
        const newDate = arr.length == i+1 || moment(v.timestamp).tz(Localization.timezone).isAfter(arr[i+1].timestamp, 'day')
        if (newDate) {
            return [{message: v}, {time: v.timestamp}] as ListItem[]
        }
        return {
            message: v
        } as ListItem    
    })
}