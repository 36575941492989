import {useUserMarkers} from "../../server/User";
import {Map, Marker, Overlay} from "pigeon-maps";
import {Text, View} from "react-native";
import dayjs from "dayjs";

interface Props {
    uid: string
}

export default function MarkerMap({uid} : Props) {
    const {userMarker} = useUserMarkers(uid)

    return (
        <Map defaultCenter={[48.099568, 11.788862]} defaultZoom={11}>
            {
                userMarker ? (
                    userMarker.map((marker) => (
                        <Marker
                            key={marker.timestamp + marker.coordinates.lat + marker.coordinates.long}
                            anchor={[marker.coordinates.lat, marker.coordinates.long]}
                            width={50}
                            color={marker.radius >= 3000 ? "red" : "blue"}

                        />
                    ))
                ) : null
            }
            {
                userMarker ? (
                    userMarker.map((marker) => (
                        <Overlay
                            key={marker.timestamp + marker.coordinates.lat + marker.coordinates.long}
                            anchor={[marker.coordinates.lat, marker.coordinates.long]}
                            offset={[75, 85]}
                        >
                            <View style={{backgroundColor: "white", borderRadius: 25, padding: 5}}>
                                <Text>{dayjs(marker.timestamp).format("LLL")}</Text>
                            </View>
                        </Overlay>
                    ))
                ) : null
            }
        </Map>
    )
}
