import React, { useContext } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { UserListSortingContext } from '../contexts/UserListSortingContext';
import ListHeaderTitle from './userList/ListHeaderTitle';

export default function ListHeader() {

    const {setSort} = useContext(UserListSortingContext)

    return(
        <View style={styles.container}>
            <ListHeaderTitle style={styles.text} title={"Nummer"} onClick={() => setSort('Number')}/>
            <ListHeaderTitle style={styles.text} title={"Letzter Login"} onClick={() => setSort('Login')} />
            <ListHeaderTitle style={styles.text} title={"Accounderstellung"} onClick={() => setSort('Creation')} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'stretch',
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 25,
        marginVertical: 5
    },
    text: {
        flex: 1,
        alignSelf: 'stretch'
    }
})