import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native';
import { Icon, Image } from '@rneui/themed';
import { getUserImg } from '../../server/Auth';
import { useTheme } from '../../utils/Style';
import firebase from 'firebase/app'

interface Props{
    imageuid: string
}

export default function LoadableImage({imageuid} : Props) {

    const [img, setImg] = useState<string>();

    const Style = useTheme();

    const styles = StyleSheet.create({
        img: {
            width: "100%", height: "100%", resizeMode: 'contain'

        },
        imgctn: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        }
    });

    const onPress = () => {
        if(!img) {
            getUserImg(imageuid).then((v) => {
                setImg(v)
            }).catch((e) => console.error(e))
        }
    }


    return(
        <View style={{width: 200, height: 200, backgroundColor: Style.type == 'light' ? 'white' : 'grey', borderRadius: 25, justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}} >
            <Icon style={{opacity: img ? 0 : 1}} name="reload" type="ionicon" reverse size={30} color={Style.type == "light" ? '#e6f2ff' : 'white'} reverseColor={Style.type == "light" ? "white" : "grey"} onPress={onPress} />            
            {
                !img ? null : (<Image resizeMode="contain" source={{uri : img}} containerStyle={styles.imgctn} style={styles.img}/>)
            }
        </View>
    )
}
