import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, StyleSheet, Text, View } from 'react-native';
import { Icon, Overlay } from '@rneui/themed';
import LoadingComp from '../../components/LoadingComp';
import LoadableImage from '../../components/reports/LoadableImage';
import ReportedUserInfo from '../../components/reports/ReportedUserInfo';
import ReportInfoBox from '../../components/reports/ReportInfoBox';
import ReportSpecifcInfo from '../../components/reports/ReportSpecificInfo';
import { ReportParamsList } from '../../navigation/ReportNavigator';
import { getReport, useReport } from '../../server/Reports';
import ReportConfirmation from './ReportConfimation';
import ReportHeaderRight from './ReportHeaderRight';

type RProp = RouteProp<ReportParamsList, 'User'>
type NavProp = StackNavigationProp<ReportParamsList, 'User'>;

interface Props {
    route : RProp;
}

export default function ReportedUser({route} : Props) {

    console.log(route.params)
    const {id} = route.params;

    const navigation = useNavigation<NavProp>();
    const report = useReport(id);
    const [finish, setFinish] = useState(false);

    const headerright = () => (
        <Icon name="check" type="feather" onPress={() => setFinish(true)} style={{marginEnd: 20}} />
    )

    useEffect(() => {
        if(report){
            navigation.setOptions({
                headerRight: headerright,
                headerTitle: "User : "+report.ruid,
            })
        }
    }, [report])

    if(!report) {
        return(
            <LoadingComp />
        )
    }

    console.log(report);

    const styles = StyleSheet.create({
        container: {
            flex: 1,
        },
        reportinfobox: {
            flexDirection: 'row',
            flex: 1,
            margin: 10
        },
        leftbox: {
            flex: 1,
            marginEnd: 10
        },
        rightbox: {
            flex: 1,
            marginStart: 10
        },
        imgctn: {
            alignItems: 'center'
        }
    })

    return(
        <View style={styles.container}>
            <View style={styles.reportinfobox}>
                <View style={styles.leftbox}>
                    <ReportedUserInfo uid={report.ruid} />
                </View>
                <View style={styles.rightbox}>
                    <View style={styles.imgctn}>
                        <LoadableImage imageuid={report.ruid} />
                    </View>
                    <ReportInfoBox item={report} />
                    <ReportSpecifcInfo item={report} />
                </View>
            </View>
            <ReportConfirmation reset={() => {setFinish(false)}} visible={finish} item={report} />
        </View>
    )
}
