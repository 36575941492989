import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import { Button, Overlay } from '@rneui/themed';
import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { AccountStackParamList } from '../../navigation/AccountNavigator';

type NavigationProp = StackNavigationProp<AccountStackParamList, 'Root'>;

export default function AccountScreen() {

    const user = getAuth().currentUser;
    const navigation = useNavigation<NavigationProp>();

    if(!user) {
        return(
            <Text>User Undefined!</Text>
        )
    }

    const sendResetEmail = async () => {
        if(user.email){
            try{
                await sendPasswordResetEmail(getAuth(), user.email)
            } catch (e)  {
                console.error(e)
            }
        }

    }

    const styles = StyleSheet.create({
        container: {
            alignItems: 'center',
            padding: 10
        },
        accountbox: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
            backgroundColor: 'white',
            borderRadius: 25,
            minWidth: 500
        },
        btnctn: {
            flex: 1,
            marginVertical: 20
        },
        pswdctn: {
            flexDirection: 'row',
            margin: 20,
        },
        pwdinput: {
            padding: 10,
        },
        btn: {
            marginTop: 20
        }
    })

    return(
        <View style={styles.container}>
            <View style={styles.accountbox}>
                <BoxRow title={"Displayname :"} text={user.displayName ?? user.email ?? user.uid} />
                <BoxRow title={"Created :"} text={user.metadata.creationTime ?? "Unbekannt"} />
                <BoxRow title={"Last Login :"} text={user.metadata.lastSignInTime ?? "Unbekannt"} />
            </View>
            <View style={styles.btnctn}>
                <Button title="Alle Mitarbeiter" onPress={() => navigation.navigate('ServiceUserList', {page: 1})} />
                <Button title="Passwordreset" style={styles.btn} onPress={() => sendResetEmail()} />
                <Button title="Abmelden" style={styles.btn} onPress={() => signOut(getAuth())} />
            </View>
        </View>
    )
}

const BoxRow = ({title, text} : {title : string, text : string}) => {

    return(
        <View style={{flex: 1, alignSelf: 'stretch', flexDirection: 'row', marginVertical: 5}}>
            <Text style={{flex: 1, alignSelf: 'stretch'}}>{title}</Text>
            <Text style={{flex: 1, alignSelf: 'stretch'}}>{text}</Text>
        </View>
    )
}
