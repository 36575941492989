import { createContext, useCallback, useContext, useState } from "react";

interface Context {
    sort?: UserListSorting
    setSort: (value?: SortType) => void
}

export type SortType = "Login" | "Creation" | "Number"

interface UserListSorting {
    type: SortType
    sort: "asc" | "desc"
}

const defValue : Context = {
    setSort: () => console.warn("Default fkt!")
}

export const UserListSortingContext = createContext<Context>(defValue)

interface Props {
    children?: React.ReactNode
}

export function UserListSortingProvider({children} : Props) {
    const [state, setState] = useState<UserListSorting | undefined>()

    const setValue = useCallback((sort?: SortType) => {
        console.log(sort)
        if(sort){
            if(state && sort == state.type){
                setState({
                    type: state.type,
                    sort: state.sort === 'asc' ? 'desc' : 'asc'
                })
            }else {
                setState({
                    type: sort,
                    sort: 'asc'
                })
            }
        } else {
            setState(undefined)
        }
    }, [setState, state])

    const data : Context = {
        sort: state,
        setSort: setValue
    }

    console.log(data)

    return <UserListSortingContext.Provider children={children} value={data} />
}