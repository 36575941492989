import {StyleSheet, Text, View} from "react-native";
import {Icon, ListItem} from "@rneui/themed";
import CreateHash from "./CreateHash";
import {useUserMarkers} from "../../server/User";
import {Marker} from "../../utils/Structs";
import {useState} from "react";
import dayjs from "dayjs";
import {useNavigation} from "@react-navigation/native";

interface Props {
    uid: string
}

export default function MarkerList({uid} : Props) {
    const {userMarker} = useUserMarkers(uid)
    const navigation = useNavigation()

    return(
        <View style={styles.container}>
            <View style={{flexDirection: "row", alignItems: 'center'}}>
                <Text style={styles.title}>Markers</Text>
                {
                    userMarker && userMarker.length > 0 ?
                        <Icon name={"map"} type={"feather"} reverse onPress={() => navigation.navigate("UserMarkerMap", {uid: uid})} size={14} />
                        : null
                }
            </View>
            {
                userMarker && userMarker.length > 0 ? (
                    userMarker?.map((marker, index) => (
                        <Accordion key={marker.timestamp + marker.coordinates.long + marker.coordinates.lat} marker={marker} />
                    ))
                ) : (
                    <Text>No Markers set</Text>
                )
            }

        </View>
    )

}

function Accordion({marker} : {marker: Marker}) {
    const [open, setOpen] = useState(false)

    return(
        <ListItem.Accordion
            onPress={() => setOpen(!open)}
            isExpanded={open}
            key={marker.timestamp}
            content={
                <View style={styles.headerStyle}>
                    <Text style={{marginEnd: 10, color: marker.radius >= 3000 ? "red" : "blue"}}>{dayjs(marker.timestamp).format("LLL")}</Text>
                </View>
            }
        >
            <Text>{`Type: ${marker.radius >= 3000 ? "Big" : "Small"}`}</Text>
            <Text>{`Lat: ${marker.coordinates.lat}`}</Text>
            <Text>{`Long: ${marker.coordinates.long}`}</Text>
        </ListItem.Accordion>
    )
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 25,
        backgroundColor: "white",
        padding: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: 15,
        fontWeight: "500"
    },
    headerStyle: {
        flexDirection: "row",
        alignItems: 'center'
    },
    hashText: {
        alignSelf: 'center'
    }
})
