import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { Icon } from '@rneui/themed';
import { useTheme } from '../utils/Style';

interface Props{
    errormsg : string;
    style? : StyleProp<ViewStyle>
}

export default function ErrorComponent({errormsg, style} : Props){
    const Style = useTheme()
    
    const styles = StyleSheet.create({
        container: {
            backgroundColor: Style.componentcolor,
            borderRadius: 25,
            flex : 0,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 30,
            margin: 10
        },
        icon: {
            flex: 1,
        },
        text: {
            flex : 1,
            fontSize: 15,
            textAlign: 'auto',
            marginStart: 20,
            color: Style.textcolor
        }
    });

    return(
        <View style={[styles.container, style]}>
            <Icon size={60} color={"orange"} name={"exclamationcircle"} type={"antdesign"} />
            <Text style={styles.text}>{errormsg}</Text>
        </View>
    )
}
