import { useEffect, useState } from 'react';
import { ColorSchemeName, DeviceEventEmitter, useColorScheme } from 'react-native';

export interface Style{
    type : StyleType;
    accentcolor : string;
    componentcolor : string;
    textcolor : string;
}

export type StyleType = "light" | "dark";

export const light = {
    type: "light",
    accentcolor : "black",
    componentcolor : "rgba(255,255,255,1)",
    textcolor : "black",
} as Style;

export const dark = {
    type: "dark",
    accentcolor : "lightblue",
    componentcolor : "#323232ff",
    textcolor: "white"
} as Style;

const parseColorScheme = (colorscheme : ColorSchemeName) : Style => {
    if(colorscheme === "light") {
        return light;
    } else if(colorscheme === "dark"){
        return dark;
    }
    return light;
}

export function useTheme() {
    const colorScheme = useColorScheme();
    const [theme, setTheme] = useState<Style>(parseColorScheme(colorScheme));

    useEffect(() => {
        setTheme(parseColorScheme(colorScheme));
    }, [colorScheme])

    return theme;
}