import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, User } from 'firebase/auth';
import LoginScreen from './src/screens/LoginScreen';
import NavigationRoot from './src/navigation';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import dayjs from "dayjs";
import 'dayjs/locale/de'

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat)
dayjs.locale('de') // use locale globally

const firebaseConfig = {
  apiKey: "AIzaSyAF9YgR2HlVgqcAf8T0H2o7qtmWGLx9K6M",
  authDomain: "fkservice-a074f.firebaseapp.com",
  projectId: "fkservice-a074f",
  storageBucket: "fkservice-a074f.appspot.com",
  messagingSenderId: "92405299305",
  appId: "1:92405299305:web:8bd74d291ddb74921e0af8"
};
// Initialize Firebase
if(getApps().length === 0){
  initializeApp(firebaseConfig);
}

export default function App() {

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const sub = getAuth().onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return sub;
  }, []);

  if(loading) {
    return(
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator size={'large'} />
      </View>
    )
  }

  if(!user) {
    return(<LoginScreen />);
  }

  return (
    <SafeAreaProvider>
      <NavigationRoot />
      <StatusBar style="auto" />
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
