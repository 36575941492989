import {RouteProp} from "@react-navigation/core";
import {UserStackParams} from "../../navigation/UserStackNavigator";
import {StackNavigationProp} from "@react-navigation/stack";
import MarkerMap from "../../components/user/MarkerMap";

type RProp = RouteProp<UserStackParams, 'UserHashMap'>;
type NProp = StackNavigationProp<UserStackParams, 'UserHashMap'>

interface Props{
    route : RProp;
}

export default function UserMarkerMapScreen({route} : Props) {


    return(
        <MarkerMap uid={route.params.uid} />
    )
}
