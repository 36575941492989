import React from 'react';
import {createDrawerNavigator} from '@react-navigation/drawer';
import HomeScreen from '../screens/HomeScreen';
import UserStackNavigator from './UserStackNavigator';
import ReportNavigator from './ReportNavigator';

export type DrawerParamList = {
    Root : undefined;
    Users : undefined;
    Reports : undefined;
}

const Drawer = createDrawerNavigator<DrawerParamList>()

export default function DrawerNavigation() {

    return(
        <Drawer.Navigator
        initialRouteName="Root"
        >
            <Drawer.Screen name="Root" component={HomeScreen} options={{title: "Home"}} />
            <Drawer.Screen name="Users" component={UserStackNavigator} />
            <Drawer.Screen name="Reports" component={ReportNavigator} />
        </Drawer.Navigator>
    )
    
}