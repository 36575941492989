import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import ReportList from '../../components/ReportList'
import { RType } from '../../utils/Structs'

export default function ReportScreen() {

    const styles = StyleSheet.create({
        container: {
            flex : 1,
            marginVertical: 20
        }
    })

    return(
        <View style={styles.container}>
            <ReportList />
        </View>
    )
}

export function parseType(type : RType) {
    switch(type) {
        case 'msg': return "Chatmeldung"
        case 'req': return "Anfragenmeldung"
        case 'img': return "Profilbildmeldung"
        default: return "Unbekannter Typ: " +type
    }
}