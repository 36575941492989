import { RouteProp } from '@react-navigation/core';
import React, { useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { Button } from '@rneui/themed';
import ListHeader from '../../components/ListHeader';
import LoadingComp from '../../components/LoadingComp';
import { AccountStackParamList } from '../../navigation/AccountNavigator';
import { useUserList } from '../../server/Service';
import { UserStruct } from '../../utils/Structs';
import ServiceUserListItem from './ServiceUserListItem';

type RProp = RouteProp<AccountStackParamList, 'ServiceUserList'>;

interface Props {
    route : RProp;
}

export default function ServiceUserList({route} : Props) {
    const [page, setPage] = useState(route.params?.page ?? 1);
    const {users, end} = useUserList(1);

    if(!users) {
        return(
            <LoadingComp />
        )
    }

    const renderUsers = ({item} : {item : UserStruct}) => (
        <ServiceUserListItem item={item} />
    )

    const keyExtractor = (item : UserStruct, index : number) => (item.uid)

    const styles = StyleSheet.create({
        row: {
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },
        container: {
            flex: 1,
            margin: 20
        }
    })

    return(
        <View style={styles.container}>
            <FlatList style={{flex : 1}} data={users} renderItem={renderUsers} keyExtractor={keyExtractor} ListHeaderComponent={ListHeader} />
            <View style={styles.row}>
                <Button title="Weiter" disabled={end} />
            </View>
        </View>
    )
}
