import {useUserLocationHash} from "../../server/User";
import {GoogleMap, InfoWindow, LoadScript, Rectangle} from "@react-google-maps/api";
import dayjs from "dayjs";
import {useMemo, useRef} from "react";
import _ from "lodash";

interface Props {
    uid: string
}

const center = { // Baldham as default
    lat: 48.099568,
    lng: 11.788862
};

const mapContainerStyle = {
    height: "100%",
    width: "100%"
};

export default function HashMap({uid}: Props) {

    const {userHashes} = useUserLocationHash(uid)

    const group = useMemo(() => {
        if(!userHashes){
            return {}
        }
        const data = _.flatten(userHashes.map(({geohash, ...rest}) => {
            return geohash.map((hash) => ({
                geohash: hash,
                ...rest
            }))
        }))
        return _.groupBy(data, value => value.geohash)
    }, [userHashes])

    const hashCenter = useMemo(() => {
        const values = _.flatten(Object.values(group))
        if(values.length > 0) {
            const decoded = decode(values[0].geohash)
            return {
                lat: decoded.lat,
                lng: decoded.lon
            }
        }
        return center
    }, [group])

    return(
        <LoadScript
            googleMapsApiKey={"AIzaSyAF9YgR2HlVgqcAf8T0H2o7qtmWGLx9K6M"}
        >
            <GoogleMap
                id="InfoBox-example"
                mapContainerStyle={mapContainerStyle}
                zoom={13}
                center={hashCenter}
            >
                {
                    Object.entries(group).map(value => (
                        <HashRect key={value[0]} geohash={value[0]} hashes={value[1]} />
                    ))
                }
            </GoogleMap>
        </LoadScript>
    )

    // return(
    //     <LoadScript
    //         googleMapsApiKey={"AIzaSyAF9YgR2HlVgqcAf8T0H2o7qtmWGLx9K6M"}
    //     >
    //         <GoogleMap
    //             id="InfoBox-example"
    //             mapContainerStyle={mapContainerStyle}
    //             zoom={10}
    //             center={center}
    //         >
    //             {
    //                 userHashes?.map((hash) => (
    //                     <>
    //                         {
    //                             hash.geohash.map((geohash) => (<HashRect key={hash.timestamp+geohash} geohash={geohash} hash={hash} />))
    //                         }
    //                     </>
    //                 ))
    //             }
    //         </GoogleMap>
    //     </LoadScript>
    // )

}

function HashRect({geohash, hashes} : {geohash: string, hashes: {sender: string, timestamp: number, geohash: string}[]}) {
    const ref = useRef<InfoWindow>()
    const infoWindow = useRef<google.maps.InfoWindow>()

    const {sw, ne} = bounds(geohash)
    const {lat: latCentre, lon: lonCentre} = decode(geohash)
    return(
        <>
            <Rectangle
                bounds={new google.maps.LatLngBounds(
                    new google.maps.LatLng({lat: sw.lat, lng: sw.lon}),
                    new google.maps.LatLng({lat: ne.lat, lng: ne.lon})
                )}
                onClick={() => {
                    console.log("Rect pressed")
                    if(infoWindow.current) {
                        ref.current?.open(infoWindow.current)
                    }
                }}
            />
            <InfoWindow
                position={{lat: latCentre, lng: lonCentre}}
                onLoad={(window) => infoWindow.current = window}
                ref={ref}
            >
                <div style={{padding: 10, background: "white", alignItems: 'center'}}>
                    <h3>{`Hash: ${geohash}`}</h3>
                    {
                        hashes.map((hash) => (
                            <h4 key={hash.timestamp}>{dayjs(hash.timestamp).format("LLL")}</h4>
                        ))
                    }
                </div>
            </InfoWindow>
        </>
    )
}

const base32 = '0123456789bcdefghjkmnpqrstuvwxyz'; // (geohash-specific) Base32 map

/**
 * Returns SW/NE latitude/longitude bounds of specified geohash.
 *
 * @param   {string} geohash - Cell that bounds are required of.
 * @returns {{sw: {lat: number, lon: number}, ne: {lat: number, lon: number}}}
 * @throws  Invalid geohash.
 */
function bounds(geohash: string) {
    if (geohash.length == 0) throw new Error('Invalid geohash');

    geohash = geohash.toLowerCase();

    let evenBit = true;
    let latMin = -90, latMax = 90;
    let lonMin = -180, lonMax = 180;

    for (let i = 0; i < geohash.length; i++) {
        const chr = geohash.charAt(i);
        const idx = base32.indexOf(chr);
        if (idx == -1) throw new Error('Invalid geohash');

        for (let n = 4; n >= 0; n--) {
            const bitN = idx >> n & 1;
            if (evenBit) {
                // longitude
                const lonMid = (lonMin + lonMax) / 2;
                if (bitN == 1) {
                    lonMin = lonMid;
                } else {
                    lonMax = lonMid;
                }
            } else {
                // latitude
                const latMid = (latMin + latMax) / 2;
                if (bitN == 1) {
                    latMin = latMid;
                } else {
                    latMax = latMid;
                }
            }
            evenBit = !evenBit;
        }
    }

    const bounds = {
        sw: {lat: latMin, lon: lonMin},
        ne: {lat: latMax, lon: lonMax},
    };

    return bounds;

}

function decode(geohash : string) {

    const boundsData = bounds(geohash); // <-- the hard work
    // now just determine the centre of the cell...

    const latMin = boundsData.sw.lat, lonMin = boundsData.sw.lon;
    const latMax = boundsData.ne.lat, lonMax = boundsData.ne.lon;

    // cell centre
    let lat = (latMin + latMax)/2;
    let lon = (lonMin + lonMax)/2;

    // round to close to centre without excessive precision: ⌊2-log10(Δ°)⌋ decimal places
    lat = Number(lat.toFixed(Math.floor(2-Math.log(latMax-latMin)/Math.LN10)));
    lon = Number(lon.toFixed(Math.floor(2-Math.log(lonMax-lonMin)/Math.LN10)));

    return { lat: lat, lon: lon };
}
