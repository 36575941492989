import {getAuth} from 'firebase/auth'


export const url = __DEV__ ? "http://localhost:3000/" : "https://backend.admin.revoir.frozenfacesapps.de/"
export const wsurl = __DEV__ ? "ws://localhost:3000/" : "wss://backend.admin.revoir.frozenfacesapps.de/"

export function getAuthToken() {
    const user = getAuth().currentUser;
    if(!user) {
        throw new Error("User undefined!");
    }
    return user.getIdToken()
}
