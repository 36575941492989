import React, { useEffect, useState } from 'react';
import { Alert, StyleSheet, View } from 'react-native';
import { Button, Input } from '@rneui/themed';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';

export default function LoginScreen() {

    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if(email.length > 0 && pwd.length > 0) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [email, pwd]);

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        input: {
            width: '50%',
        }
    });

    const login = async () => {
        setVisible(false);
        try{
            await signInWithEmailAndPassword(getAuth(), email, pwd);
        } catch (e) {
            const {code, message} = e;
            if(code === "auth/wrong-password") {
                Alert.alert("Wrong password");
            } else {
                Alert.alert(message);
            }
        }

    }

    return(
        <View style={styles.container}>
            <Input containerStyle={styles.input} placeholder={"E-Mail Adresse"} label={"Email Adresse"} onChangeText={(text) => setEmail(text)} textContentType={'emailAddress'} />
            <Input containerStyle={styles.input} placeholder={"Password"} label={"Password"} secureTextEntry onChangeText={(text) => setPwd(text)} onSubmitEditing={() => {if(visible) {login()}}} />
            <Button title={"Login"} onPress={login} disabled={!visible} />
        </View>
    )
}
