import {useState, useCallback, useRef} from 'react'
import { updateCoins } from '../server/Auth'

export default function useChangeableCoins(reset : () => void, uid : string, parsedCoins? : number) {
    const coins = useRef(parsedCoins ?? 0)
    const [processing, setProcessing] = useState(false)
    const setCoins = useCallback((newCoins : number) => coins.current = newCoins, [])

    const change = async () => {
        if(processing){
            return
        }
        setProcessing(true)
        try{
            await updateCoins(uid, coins.current)
        }catch(e){
            console.warn(e)
        }
        reset()
        setProcessing(false)
    }

    return {processing, change, setCoins}
}