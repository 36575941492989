import { StyleProp, StyleSheet, Text, TextStyle } from "react-native"
import { TouchableOpacity } from "react-native-gesture-handler"

interface Props {
    title: string,
    style?: StyleProp<TextStyle>
    onClick?: () => void
}

export default function ListHeaderTitle({title, onClick, style} : Props) {

    return(
        <TouchableOpacity onPress={onClick} containerStyle={style}>
            <Text>{title}</Text>
        </TouchableOpacity>
    )

}