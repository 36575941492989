import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import { Text, View , StyleSheet} from 'react-native';
import { Overlay, Icon, Button } from '@rneui/themed';
import LoadingComp from './LoadingComp';

const def : {yes : string, no : string} = {
    yes: "Ja ich bin mir sicher!", 
    no: "Nein lieber nicht!"
}

interface Props {
    types : string[];
    prompt : {msg : string, yes?: string, no? : string}[];
    actions : (() => Promise<void>)[];
    visible: boolean;
    reset : () => void;
    type? : string;
}

export default function ComfirmationOnly({visible, reset, actions, types, prompt, type} : Props) {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<boolean | string>();
    const index = type ? types.indexOf(type) : undefined;

    useEffect(() => {
        if(!visible && response !== undefined) {
            setResponse(undefined);
        }
    },[visible])

    const press = async (success : boolean) => {
        if(!success){ //Abort on no success
            reset()
            return
        }
        setLoading(true)
        if(index === undefined) {
            setResponse("Index undefined")
        }else {
            try{
                await actions[index]();
                setResponse(true)
            } catch (e : any) {
                console.log(e)
                setResponse(e.toString())
            }
        }            
        setLoading(false);
    }

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            margin: 20
        },
        overlay: {
            borderRadius: 25
        },
        icon: {
            
        },
        iconsubtitle: {
            textAlign: 'center'
        },
        column: {
            flex: 1,
            alignContent: 'center'
        },
        row: {
            flex: 1,
            flexDirection: 'row',
            marginTop: 20,
        },
        title: {
            textAlign: 'center',
            fontSize: 15,
        }
    })

    const getContent = () => {
        if(loading) {
            return(
                <LoadingComp />
            )
        }
        if(response) {
            const success = response === true;
            return(
                <View style={styles.container}>
                    {
                        success ? (
                            <View style={{alignItems: "center", justifyContent: 'center'}}> 
                                    <Icon style={{}} name={"check-circle"} type={"feather"} color={"green"} size={35} />
                                    <Text style={ {marginTop: 10,fontSize: 20, color: "green"}}>Gesendet</Text>
                            </View>
                        ) : (
                            <View style={{alignItems: "center", justifyContent: 'center'}}> 
                                <Icon style={{}} name={"minus-circle"} type={"feather"} color={"red"} size={35} />
                                <Text style={{marginTop: 10,fontSize: 20, color: "red"}}>Fehler : {response}</Text>
                            </View>
                        )
                    }
                </View>
            )
        }
        if(type === undefined || index === undefined){
            return(
                <View style={styles.container}>
                    <Text style={styles.title}>FEHLER ?</Text>
                </View>
            )
        }else {
            const promp = prompt[index];
            return(
                <View style={{justifyContent: 'center', alignItems: 'center', margin: 30}}>
                    <Text style={styles.title}>{promp.msg}</Text>
                    <View style={{flexDirection: 'row', width: '100%', marginTop: 20}}>
                        <Button icon={{
                            name: "check-circle",
                            type: "feather",
                            size: 30,
                            color: "white"
                        }} 
                        titleStyle={{ marginEnd: promp.yes ? 10 : 0}}
                        title={promp?.yes}
                        iconContainerStyle={{marginStart: promp.yes ? 10 : 0}}
                        containerStyle={{width: '50%', borderTopEndRadius : 0, borderBottomEndRadius : 0}}
                        ViewComponent={LinearGradient}
                        linearGradientProps={{
                            colors: ['green', 'white'],
                            start: { x: 0, y: 0.5 },
                            end: { x: 1, y: 0.5 },
                        }}
                        onPress={() => press(true)}
                        />
                        <Button icon={{
                            name: "minus-circle",
                            type: "feather",
                            size: 30,
                            color: "darkred"
                        }}
                        titleStyle={{ marginStart: promp.no ? 10 : 0}}
                        title={promp?.no}
                        iconContainerStyle={{marginEnd: promp.no ? 10 : 0}}
                        iconRight
                        containerStyle={{width: '50%'}}
                        ViewComponent={LinearGradient}
                        linearGradientProps={{
                            colors: ['red', 'white'],
                            start: { x: 1, y: 0.5 },
                            end: { x: 0, y: 0.5 },
                        }}
                        onPress={() => press(false)}
                        />
                    </View>
                </View>
            )
        }
    }

    return(
        <Overlay isVisible={visible} overlayStyle={styles.overlay} onBackdropPress={() => {if(!loading){ reset() }}}>
            {
                getContent()
            }
        </Overlay>
    )
}
