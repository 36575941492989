import React from 'react'
import { FlatList, StyleSheet, View, Text } from 'react-native';
import { Button } from '@rneui/themed';
import { useReports } from '../server/Reports'
import { ReportStruct } from '../utils/Structs';
import LoadingComp from './LoadingComp';
import ReportListItem from './ReportListItem';

export default function ReportList() {
    
    const {reports, page, next, previous, end} = useReports();

    if(reports.length === 0) {
        return(
            <LoadingComp />
        )
    }

    const renderItem = ({item} : {item : ReportStruct}) => (
        <ReportListItem item={item} />
    )

    const keyExtractor = (item : ReportStruct, index : number) => (item.id + item.ruid)

    console.log(reports.length);

    const styles = StyleSheet.create({
        container: {
            flex: 1,
        },
        flatlist: {
            marginTop: 10,
            width: "96%",
            alignSelf: 'center'
        },
        lastrow: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginHorizontal: 20,
        }
    })

    console.log(reports);

    return(
        <View style={styles.container}>
            <FlatList style={styles.flatlist} data={reports} renderItem={renderItem} keyExtractor={keyExtractor} ListHeaderComponent={ListHeader} />
            <View style={styles.lastrow}>
                <Button title="Weiter" disabled={end} onPress={() => next()} />
            </View>
        </View>
        
    )
    
}

function ListHeader() {

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
            alignSelf: 'stretch',
            padding: 10,
            backgroundColor: 'white',
            borderRadius: 25,
            marginVertical: 5
        },
        text: {
            flex: 1,
            alignSelf: 'stretch'
        }
    })

    return(
        <View style={styles.container}>
            <Text style={styles.text}>{"Reported UID"}</Text>
            <Text style={styles.text}>{"Typ"}</Text>
            <Text style={styles.text}>{"Zeitpunkt"}</Text>
        </View>
    )
}
