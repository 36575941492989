import React from 'react';
import { getUser } from '../../server/Auth';
import { useUser } from '../../server/User';
import LoadingComp from '../LoadingComp';
import UserInfoBox from '../UserInfoBox';

interface Props {
    uid : string;
}

export default function ReportedUserInfo({uid} : Props) {

    const user = useUser(uid);

    if(!user) {
        return (
        <LoadingComp/> 
        )
    }

    return(
        <UserInfoBox item={user} />
    )
}