import { RouteProp, useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useCallback, useEffect, useState } from 'react'
import {StyleSheet, Text, useWindowDimensions, View} from 'react-native'
import { Icon } from '@rneui/themed'
import CoinInfoBox from '../../components/CoinInfoBox'
import ComfirmOnly from '../../components/ComfirmOnly'
import ErrorComponent from '../../components/ErrorComponent'
import LoadingComp from '../../components/LoadingComp'
import LoadableImage from '../../components/reports/LoadableImage'
import UpdateCoins from '../../components/UpdateCoins'
import UserInfoBox from '../../components/UserInfoBox'
import { UserStackParams } from '../../navigation/UserStackNavigator'
import { deleteUser, deleteUserImg, disableUser, enableUser } from '../../server/Auth'
import { useUser, useUserInfo } from '../../server/User'
import HashList from "../../components/user/HashList";
import MarkerList from "../../components/user/MarkerList";

type RProp = RouteProp<UserStackParams, 'User'>;
type NProp = StackNavigationProp<UserStackParams, 'User'>

interface Props{
    route : RProp;
}

export default function UserInfoScreen({route} : Props) {

    const {uid} = route.params;
    const navigation = useNavigation<NProp>();
    const user = useUser(uid);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState<string>();
    const {userinfo, update} = useUserInfo(uid);
    const uinfos = userinfo
    const {width} = useWindowDimensions()

    const HeaderIcons = () => {

        return(
            <View style={{flexDirection: 'row', marginEnd: 10}}>
                <Icon name="image-not-supported" type="material" disabled={type !== undefined} onPress={() => setType("delimg")} style={{marginEnd: 10}} />
                <Icon name="user-slash" type="font-awesome-5" disabled={type !== undefined} onPress={() => setType("deluser")} style={{marginEnd: 10}} />
                {
                    user?.disabled ? <Icon name="user-check" type="font-awesome-5" disabled={type !== undefined} onPress={() => setType("unlockuser")} /> : <Icon name="user-lock" type="font-awesome-5" disabled={type !== undefined} onPress={() => setType("lockuser")} />
                }
            </View>
        )
    }

    useEffect(() => {
        if(user){
            navigation.setOptions({headerRight: HeaderIcons, title: "Nutzer : " + (user.phoneNumber ?? user.uid)})
        }
    }, [user])

    if(!user) {
        return(
            <LoadingComp />
        )
    }

    const styles = StyleSheet.create({
        container: {
            alignItems: 'center',
            margin: 5
        },
        img: {
            marginBottom: 10
        },
    })

    return(
        <View style={styles.container}>
            <View style={styles.img}>
                {
                    user.photoURL ? <LoadableImage imageuid={user.uid} /> : null
                }
            </View>
            <UserInfoBox item={user} />
            <CoinInfoBox coins={uinfos?.coins} onPress={() => setVisible(true)} />
            <ComfirmOnly types={["delimg","deluser","lockuser","unlockuser"]}
            prompt={[{msg: "Profilbild löschen ?"},{msg: "Nutzer löschen ?"}, {msg: "Nutzer sperren ?"}, {msg: "Nutzer entsperren"}]}
            actions={[async () => {await deleteUserImg(uid)},async () => {await deleteUser(uid)}, async () => {await disableUser(uid)}, async () => {await enableUser(uid)}]}
            reset={() => {setType(undefined)}}
            visible={type !== undefined}
            type={type}
            />
            <UpdateCoins visible={visible} coins={uinfos?.coins} reset={() => {setVisible(false); update()}} uid={uid} />
            <View style={width >= 500 ? rootStyles.locationStyleDesktop : rootStyles.locationStyleMobile}>
                <MarkerList uid={uid} />
                <HashList uid={uid} />
            </View>
        </View>
    )
}

interface HeaderProps {
    disabled : boolean;
}

const rootStyles = StyleSheet.create({
    locationStyleDesktop: {
        flex: 1, flexDirection: "row", alignItems: 'flex-start', justifyContent: 'space-around', minWidth: "50%"
    },
    locationStyleMobile: {
        flex: 1,
    }
})
