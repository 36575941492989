import moment from 'moment'
import React, { useMemo } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { Icon } from '@rneui/themed'
import { useTheme } from '../../../utils/Style'
import { ChatElement } from './Structs'

interface Props{
    msg : ChatElement
    sender? : boolean
    onLongPress? : () => void
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        maxWidth: "80%",
        borderRadius: 10,
        backgroundColor: 'grey',
        paddingHorizontal: 10,
        paddingVertical: 5,
        alignSelf: 'flex-end',
        marginVertical: 2,
    },
    timectn: {
        flex : 0,
        flexDirection: 'row'
    },
    time: {
        fontSize: 10,
        color: 'white'
    },
    icon: {
        marginStart: 3
    },
    text: {
        color: 'white'
    }
})

const systemStyle = StyleSheet.create({
    container: {
        alignSelf: 'center',
        marginVertical: 20
    },
    text: {
        color: 'darkgrey',
        textAlign: 'center'
    }
})

const margin = 7

export default function ChatMessageItem({msg, sender, onLongPress} : Props) {

    const Style = useTheme()

    if(msg.system) {
        return(
            <View style={systemStyle.container}>
                <Text style={systemStyle.text}>{msg.message}</Text>
            </View>
        )
    }

    const colors : {sender : string, receiver : string, text : string, time : string, sent : string} = useMemo(() => {
        return Style.type == 'light' ? {
            sender: '#f47e80',
            receiver: '#e6e6e6',
            text: 'black', 
            time: '#666666',
            sent: '#666666'
        } : {
            sender: '#de4b6a',
            receiver: Style.componentcolor,
            text: '#e6e6e6', 
            time: '#999999',
            sent: '#999999'
        }
    }, [Style.type])

    return(
        <TouchableOpacity style={[styles.container, {alignSelf: sender ? 'flex-end' : 'flex-start', marginEnd : sender ? margin : 0, marginStart: sender ? 0 : margin, backgroundColor: sender ? colors.sender : colors.receiver}]} onLongPress={onLongPress}>
            <Text style={[styles.text, {color: colors.text}]}>{msg.message}</Text>
            <View style={[styles.timectn, {alignSelf: sender ? 'flex-end' : 'flex-start'}]}>
                <Text style={[styles.time, {color : colors.time}]}>{moment(msg.timestamp).locale("de").format("LT")}</Text>
                {
                    !msg.pending && sender && (
                        <Icon name="check" type="Feather" size={13} style={styles.icon} color={colors.sent} />
                    )
                }
            </View>
        </TouchableOpacity>
    )
}
