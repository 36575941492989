import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import HeaderComp from '../components/HeaderComp';

export default function HomeScreen() {

    const styles = StyleSheet.create({
        container: {
            flex: 1,
        },
        content: {
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1
        }
    });

    return(
        <View style={styles.container}>
            <HeaderComp />
            <View style={styles.content}>
                <Text>{"Hier könnte das HomeDashboard sein 😎"}</Text>
            </View>
        </View>
    )

}