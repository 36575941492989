import { NavigationProp, RouteProp } from "@react-navigation/native";
import React, { useEffect, useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";
import Chat from "../../components/general/chats/Chat";
import { ChatElement } from "../../components/general/chats/Structs"
import { RootStackParamList } from "../../navigation";
import { useChatMessages, useReportMessages } from "../../server/Reports";
import { useTheme } from "../../utils/Style";

type NProp = NavigationProp<RootStackParamList, 'ChatRoom'>
type RProp = RouteProp<RootStackParamList, 'ChatRoom'>

interface Props {
    navigation : NProp
    route : RProp
}

export default function ChatRoom({route, navigation} : Props) {

    const {id, sender} = route.params
    const {active, messages, loadMore} = useChatMessages(id)
    const parsed = useMemo(() => {
        const senderUID = sender ?? messages[0]?.sender
        console.log("sender : "+senderUID)
        return messages.map((v) => {
            return {
                message: v.message,
                timestamp: v.sendtime,
                sender: v.sender === senderUID,
                id: v.id
            } as ChatElement
        })
    }, [messages])
    console.log(parsed)
    const Style = useTheme()

    useEffect(() => {
        navigation.setOptions({
            headerTitle: `Chat : ${id}`
        })
    }, [id])

    console.log(messages)

    if(messages.length == 0){
        return(
            <View style={{justifyContent: "flex-start", alignItems : 'center', width: "95%", backgroundColor : Style.componentcolor, borderRadius : 25, marginHorizontal : 10, marginVertical : 20 }}>
                <Text style={{fontSize : 15, padding : 20, textAlign: 'center', color: Style.textcolor}}>{"Kein Chatverlauf vorhanden 🤨"}</Text>
            </View>
        )
    }

    return(
        <Chat items={parsed} containerStyle={StyleSheet.absoluteFill} onLoadMore={() => loadMore()} />
    )
}