import React from 'react';
import { Text, View } from 'react-native';
import {getAuth} from 'firebase/auth';
import { useNavigation } from '@react-navigation/core';

export default function HeaderRight() {

    const user = getAuth().currentUser;
    const navigator = useNavigation();

    return(
        <View>
            <Text onPress={() => navigator.navigate("Account")}>{user ? user?.displayName ?? user?.email ?? user.uid : "Unbekannt"}</Text>
        </View>
    )
}
