import { CompositeNavigationProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button } from '@rneui/themed';
import { RootStackParamList } from '../../navigation';
import { ReportParamsList } from '../../navigation/ReportNavigator';
import { ReportStruct, RType } from '../../utils/Structs';

type NProp = CompositeNavigationProp<StackNavigationProp<RootStackParamList, 'Root'>, StackNavigationProp<ReportParamsList, 'User'>>

interface Props {
    item: ReportStruct
}

export default function ReportSpecifcInfo({item} : Props) {

    const navigation = useNavigation<NProp>()
    
    const styles = StyleSheet.create({
        container: {
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
            backgroundColor: 'white',
            borderRadius: 25,
            marginTop: 20
        },
        rmsgbox: {
            alignItems: 'center',
            width: '100%',
            margin: 20
        },
        btn: {
            marginVertical: 10
        },
        title: {
            fontSize: 18
        }
    })

    const MessageType = () => (
        <View>
            <BoxRow title={"Chat :"} text={""} />
        </View>
    )

    const message = (msg : string) => (
        <View style={styles.rmsgbox}>
                <Text style={{}}>Gemeldete Nachricht</Text>
                <Text style={{marginTop: 20, backgroundColor: 'lightgrey', borderRadius: 25, padding: 20, width: '90%'}}>{msg}</Text>
            </View>
    )

    const getType = ( typ : RType) => {

    }

    return(
        <View style={styles.container}>
            <Text style={styles.title}>Meldungsspezifische Informationen: </Text>
            {
                item.message ? message(item.message) : null
            }
            {
                item.roomid ? <Button title={"Chatroom"} style={styles.btn} onPress={() => navigation.navigate('ChatRoom', {id: item.roomid ?? "", sender: item.sender})} /> : null 
            }
            {
                item.type === 'req' ? <Button title={"Request"} style={styles.btn} /> : null
            }
        </View>
    )


}

const BoxRow = ({title, text} : {title : string, text : string}) => {

    return(
        <View style={{flex: 1, alignSelf: 'stretch', flexDirection: 'row', marginVertical: 5}}>
            <Text style={{flex: 1, alignSelf: 'stretch'}}>{title}</Text>
            <Button style={{flex: 1, alignSelf: 'stretch'}} title={text}/>
        </View>
    )
}
