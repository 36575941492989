import HashMap from "../../components/user/HashMap";
import {RouteProp} from "@react-navigation/core";
import {UserStackParams} from "../../navigation/UserStackNavigator";
import {StackNavigationProp} from "@react-navigation/stack";

type RProp = RouteProp<UserStackParams, 'UserHashMap'>;
type NProp = StackNavigationProp<UserStackParams, 'UserHashMap'>

interface Props{
    route : RProp;
}

export default function UserHashMapScreen({route} : Props) {


    return(
        <HashMap uid={route.params.uid} />
    )
}
