import moment from 'moment'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import * as Localization from 'expo-localization';

interface Props {
    time : number
}

const styles = StyleSheet.create({
    container: {
        flex : 1,
        alignSelf: 'center',
    },
    text: {
        color: 'darkgrey'
    }
})

export default function ChatTimeView({time} : Props) {
    return(
        <View style={styles.container}>
            <Text style={styles.text}>{moment(time).tz(Localization.timezone).format("L")}</Text>
        </View>
    )
}