import {Button} from "@rneui/themed";
import {useState} from "react";

type Process = "IDLE" | "CONFIRM"

interface Props {
    onDelete: () => Promise<void>
}

export default function DeleteBtn({onDelete} : Props) {
    const [state, setState] = useState<Process>("IDLE")
    const [deleting, setDeleting] = useState(false)

    return(
        <Button
            buttonStyle={{
                backgroundColor: state == "CONFIRM" ? "orange" : "grey"
            }}
            title={state == "CONFIRM" ? "Confirm" : "Delete"}
            raised
            loading={deleting}
            onPress={async () => {
                if(state == "IDLE"){
                    setState("CONFIRM")
                } else {
                    setDeleting(true)

                    await onDelete()

                    console.log("Deleted!")
                    setState("IDLE")
                    setDeleting(false)
                }
            }}
        />
    )
}
